// Business logic steps :
// 1 : Find the no of year difference between the birthday year and now
// 2 : Get date for this year birthday
// 3 : Find the day difference between the person this year birthday and now.
// 4 : Day difference is positive means the birthday is already gone for the year.
// 5 : If difference is more then 182 days i.e his next birthday will be coming in another 6 month,
// so we can add 1 to the year difference. vice versa for negative difference.

export const calculateHalfBirthdayAge = (birthday: Date) => {
    const bd = birthday;
    const cd = new Date();

    let yearDiff = cd.getUTCFullYear() - bd.getUTCFullYear();

    const thisYrBd = bd.setUTCFullYear(cd.getUTCFullYear());
    const milliSecInoneDay = 1000 * 60 * 60 * 24;
    const diffInDay = (cd.getTime() - thisYrBd) / milliSecInoneDay;

    if (diffInDay > 0) {
        if (diffInDay >= 182) {
            yearDiff++;
        }
    } else {
        if (Math.abs(diffInDay) > 183) {
            yearDiff--;
        }
    }
    return yearDiff;
};

export const calculateAge = (birthday: Date) => {
    const today = new Date();
    let age = today.getUTCFullYear() - birthday.getUTCFullYear();

    const monthDifference = today.getUTCMonth() - birthday.getUTCMonth();
    const dayDifference = today.getUTCDate() - birthday.getUTCDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age -= 1;
    }

    return age;
};
