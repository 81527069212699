import {makeAutoObservable} from 'mobx';

import {
    RecommendedProductResponseCombo,
    RecommendedProductResponseIul,
    RecommendedProductResponseTerm,
    RecommendedProductResponseVul,
    RecommendedProductResponseBasic,
    ExtendedProductTypeCode,
    RecommendedProductsResponse,
} from '@amplify/athena-models';
import Phase3Api from '../api/phases/phase3Api';
import {prodMode} from '../config';
import updateField from '../helpers/updateField';
import {
    amplifyVulOfferingStates,
    lowerContributionLimitFemale,
    lowerContributionLimitMale,
} from '../helpers/vulCalculationConstants';
import {activeProductToProductTypeCodeMap} from '../slidesParts/AmplifyCalculator/TabsList';
import {mapAvailableRidersToLegacyState, mapStateToEligibleProductsRequestData} from './CalculatorStoreHelper';
import {calculateHalfBirthdayAge} from '../helpers/calculateAge';
import {type RootStore} from './Store';
import {serializable} from './sync/serialization';

const MARKETING_UTM_SOURCES = ['google', 'adwords', 'bing', 'bing.bing', 'fb', 'ig'];
const LOW_RISK_CAMPAIGNS = ['iul', 'indexed', 'infinite banking', 'top-performers-broad'];

export type QuoteType = 'temporary' | 'basic' | 'best' | 'retirement';

export type ActiveProductType = '' | 'combo' | 'term' | 'vul' | 'iul';

class CalculatorStore {
    private parentStore: RootStore;

    @serializable()
    recommendedProductType = ''; // TODO remove

    @serializable()
    recommendedProductTypeEnum: ExtendedProductTypeCode | null = null;

    @serializable()
    temporary: RecommendedProductResponseTerm | null = null;

    @serializable()
    basic: RecommendedProductResponseBasic | null = null;

    @serializable()
    best: RecommendedProductResponseCombo | null = null;

    @serializable()
    retirement: RecommendedProductResponseIul | null = null;

    @serializable()
    vul: RecommendedProductResponseVul | null = null;

    @serializable()
    activeProduct: ActiveProductType = '';

    get term(): RecommendedProductResponseTerm | null {
        return this.temporary;
    }

    get combo(): RecommendedProductResponseCombo | null {
        return this.best;
    }

    get iul(): RecommendedProductResponseIul | null {
        return this.retirement;
    }

    constructor(parentStore: RootStore) {
        this.parentStore = parentStore;
        makeAutoObservable(this);
    }

    hasQuote(type: string): boolean {
        switch (type) {
            case 'best':
                return !!this.best?.iulQuote && !!this.best?.termQuote;
            case 'retirement':
                return !!this.retirement?.quote;
            case 'temporary':
                return !!this.temporary?.quote;
        }
    }

    hasRetirementRider(): boolean {
        return !!this.retirement?.riderAvailable;
    }

    get isVulPreferred(): boolean {
        return !!this.vul?.product?.isPreferred;
    }

    updateField = updateField.bind(this);

    get isAmplifyVulPrequalify(): boolean {
        if (!this.meetsVulLowerContributionLimit) {
            console.debug('Does not meet contribution limit requirement');
            return false;
        }

        const phase1 = this.parentStore.userData.phase1;
        if (!amplifyVulOfferingStates.has(phase1.zipCodeState) && phase1.zipCode != '85902') {
            //ENG-1005 protect amplifyVUL remove to release amplify vul
            console.debug('Does not meet zip code requirement');
            return false;
        }

        // const selectedMinIncome = annualHouseholdIncome.find((item) => item.value === phase1.annualHouseholdIncome);

        //ENG-1005 protect amplifyVUL. To release amplifyVUL switch if statement back to commented out conditional
        // if (selectedMinIncome.min < amplifyVulMinimumHouseholdIncome) {
        if (phase1.annualHouseholdIncomeText !== '12683957') {
            console.debug('Does not meet min income requirement');
            return false;
        }

        if (phase1.investmentRisk !== 'high') {
            console.debug('Does not meet investment risk requirement');
            return false;
        }
        return true;
    }

    // TODO move all this to backend
    get meetsVulLowerContributionLimit(): boolean {
        const risk = this.parentStore.userData.phase1.investmentRisk;
        const savings = this.parentStore.userData.phase1.features.includes('savings');
        const comfortableAmount = this.parentStore.userData.phase1.comfortableAmount;
        const {phase1, utm} = this.parentStore.userData;

        // with urgent request to add equitable equation back, the following is a temporary solution to determine whether this customer
        // is eligible for VUL or not. Few criterias.
        // 1) Age needs to be between 20 and 55
        // 2) Based on age and gender, a different 'comfortable amount' needs to be used.
        // this map is defined in a helper file
        // additionally, the user must have selected they wanted savings plan with risk to be moderate or high
        // or they came from one of the paid search engines without low risk campaign and has HHI >= 50k

        // Calculate age
        const age = calculateHalfBirthdayAge(new Date(this.parentStore.userData.phase1.dateOfBirth));
        const gender = this.parentStore.userData.phase1.gender;

        return (
            age >= 20 &&
            age <= 55 &&
            ((gender === 'male' && comfortableAmount >= lowerContributionLimitMale[age - 20]) ||
                (gender === 'female' && comfortableAmount >= lowerContributionLimitFemale[age - 20])) &&
            ((risk !== 'low' && !!risk && savings) ||
                (MARKETING_UTM_SOURCES.includes(utm?.source) &&
                    phase1.annualHouseholdIncome &&
                    phase1.annualHouseholdIncome >= 3 &&
                    !LOW_RISK_CAMPAIGNS.includes(utm?.campaign?.toLocaleLowerCase())))
        );
    }
    resetQuotes = (): void => {
        this.temporary = null;
        this.basic = null;
        this.best = null;
        this.retirement = null;
        this.vul = null;
        this.recommendedProductType = '';
    };

    async updTemporary(): Promise<void> {
        const userData = this.parentStore.userData;
        const {
            phase3: {
                temporary: {yearCoverage, coveragePeriod, product, coveragePeriodRange},
            },
        } = userData;
        if (coveragePeriod && product) {
            const {userData} = mapStateToEligibleProductsRequestData(this.parentStore.userData);
            const res = await Phase3Api.getTerm({
                userData,
                productCode: product.code,
                termPeriod: coveragePeriod,
                faceAmount: yearCoverage,
                termRange: coveragePeriodRange,
                monthlyPremium: null,
            });
            this.setResultTerm(res);
        }
    }

    async updBasic(): Promise<void> {
        console.log('Basic not supported');
    }

    async updBest(): Promise<void> {
        const {
            phase3: {
                bestValue: {yearCoverage, lifetimeCoverage, iulProduct, termProduct},
                temporary: {coveragePeriod},
            },
        } = this.parentStore.userData;

        if (termProduct && iulProduct) {
            const {userData} = mapStateToEligibleProductsRequestData(this.parentStore.userData);
            const res = await Phase3Api.getCombo({
                totalFaceAmount: yearCoverage,
                iulFaceAmount: lifetimeCoverage,
                iulProductCode: iulProduct.code,
                termProductCode: termProduct.code,
                termPeriod: coveragePeriod,
                userData,
            });
            this.setResultCombo(res);
        }
    }

    async updRetirement(): Promise<void> {
        const userData = this.parentStore.userData;
        const {
            phase3: {
                retirementFocused: {lifetimeCoverage, product},
            },
        } = userData;

        if (lifetimeCoverage && product) {
            const res = await Phase3Api.getIul({
                faceAmount: lifetimeCoverage,
                cashValueAtAge: null,
                monthlyPremium: null,
                productCode: product.code,
                userData: mapStateToEligibleProductsRequestData(userData).userData,
                policyAge: null,
            });

            this.setResultIul(res);
        }
    }

    initVUL = (vul: RecommendedProductResponseVul): void => {
        this.vul = vul;
    };

    resetVUL = async (): Promise<void> => {
        this.parentStore.uiStore.progressStore.resettingQuote = true;
        await this.sendData();
        this.parentStore.uiStore.progressStore.resettingQuote = false;
    };

    initTerm(res: RecommendedProductResponseTerm | null): void {
        this.setResultTerm(res || null);
        const {phase3} = this.parentStore.userData;

        phase3.updTemp('yearCoverage', res?.quote?.faceAmount);
        phase3.updTemp('coveragePeriod', res?.quote?.termPeriod);
        phase3.updTemp('defaultCoveragePeriod', res?.quote?.termPeriod);
        phase3.updTemp('product', res?.product);

        if (res?.termRange) {
            phase3.updTemp('coveragePeriodRange', res.termRange);
        }

        phase3.updTemp('illness', [
            res?.riderAvailable.critical && 'critical',
            res?.riderAvailable.chronic && 'chronic',
        ]);
    }

    initBasic(res: RecommendedProductResponseBasic): void {
        this.setResultBasic(res);
        const {phase3} = this.parentStore.userData;

        if (res) {
            phase3.updBasic('lifetimeCoverage', res.quote.faceAmount);
        }

        phase3.updBasic('illness', [
            res?.riderAvailable?.critical && 'critical',
            res?.riderAvailable?.chronic && 'chronic',
        ]);
    }

    initCombo(res: RecommendedProductResponseCombo): void {
        this.setResultCombo(res);
        const {phase3} = this.parentStore.userData;

        if (res) {
            phase3.updBest('yearCoverage', res.totalFaceAmount);
            phase3.updBest('lifetimeCoverage', res.iulQuote.faceAmount);
        }

        phase3.updBest('illness', [
            res?.riderAvailableCombined?.critical && 'critical',
            res?.riderAvailableCombined?.chronic && 'chronic',
        ]);
        phase3.updBest('iulQuote', res?.iulQuote);
        phase3.updBest('termQuote', res?.termQuote);
        phase3.updBest('iulProduct', res?.iulProduct);
        phase3.updBest('termProduct', res?.termProduct);
    }

    initIul(res: RecommendedProductResponseIul): void {
        this.setResultIul(res);
        const {phase3} = this.parentStore.userData;

        phase3.updRetir('product', res?.product);
        phase3.updRetir('lifetimeCoverage', res?.quote.faceAmount);

        phase3.updRetir('illness', [
            res?.riderAvailable?.critical && 'critical',
            res?.riderAvailable?.chronic && 'chronic',
        ]);
    }

    setResultTerm(res: RecommendedProductResponseTerm): void {
        this.updateField('temporary', res);
        const {phase3} = this.parentStore.userData;

        const riders = mapAvailableRidersToLegacyState(res?.riderAvailable);

        phase3.updResultTemporary('perMonth', res?.quote?.modalPremium || null);
        phase3.updResultTemporary('lifetimeCoverage', res?.quote?.faceAmount || null);
        phase3.updResultTemporary('yearCoverage', res?.quote?.termPeriod || null);
        phase3.updResultTemporary('carriers', [res?.product?.carrierName] || null);
        phase3.updResultTemporary('riders', riders || null);
    }

    setResultBasic(res: RecommendedProductResponseBasic): void {
        this.updateField('basic', res);
        const {phase3} = this.parentStore.userData;

        phase3.updResultBasic('perMonth', res?.quote?.modalPremium || null);
        phase3.updResultBasic('lifetimeCoverage', res?.quote?.faceAmount || null);
        phase3.updResultBasic('carriers', res?.product.carrierName || null);
        phase3.updResultBasic('riders', mapAvailableRidersToLegacyState(res?.riderAvailable) || null);
    }

    setResultCombo(res: RecommendedProductResponseCombo | null): void {
        this.updateField('best', res);
        const {phase3} = this.parentStore.userData;

        phase3.updResultBest('perMonth', res ? res.iulQuote.modalPremium + res.termQuote.modalPremium : null);
        phase3.updResultBest('perMonthIUL', res ? res.iulQuote.modalPremium : null);
        phase3.updResultBest('taxFreeCash', res ? res.iulQuote.surrenderValue : null);
        phase3.updResultBest('years', res ? res.iulQuote.policyAge : null);
        phase3.updResultBest('totalCoverage', res ? res.iulQuote.faceAmount + res.termQuote.faceAmount : null);
        phase3.updResultBest('year1_20Coverage', res?.termQuote?.faceAmount || null);
        phase3.updResultBest('year20_LifeCoverage', res?.iulQuote?.faceAmount || null);
        phase3.updResultBest('carriers', res ? [res.iulProduct.name, res.termProduct.name] : null);
        phase3.updResultBest('riders', res ? mapAvailableRidersToLegacyState(res.riderAvailableCombined) : null);
    }

    setResultIul(res: RecommendedProductResponseIul): void {
        this.updateField('retirement', res);
        const {phase3} = this.parentStore.userData;

        phase3.updResultRetirement('perMonth', res?.quote?.modalPremium || null);
        phase3.updResultRetirement('taxFreeCash', res?.quote?.surrenderValue || null);
        phase3.updResultRetirement('years', res?.quote?.policyAge || null);
        phase3.updResultRetirement('lifetimeCoverage', res?.quote?.faceAmount || null);
        phase3.updResultRetirement('carriers', [res?.product.carrierName] || null);
        phase3.updResultRetirement('riders', mapAvailableRidersToLegacyState(res?.riderAvailable) || null);
    }

    setActiveProduct(product: ActiveProductType): void {
        this.activeProduct = product;
    }

    getActiveProductCode(): ExtendedProductTypeCode | null {
        return activeProductToProductTypeCodeMap.get(this.activeProduct as ActiveProductType) || null;
    }

    retry = async (fn, args, retries = 3, delay = 1000) => {
        for (let i = 0; i < retries; i++) {
            try {
                return await fn(...args);
            } catch (error) {
                if (i === retries - 1) throw error;
                await new Promise((res) => setTimeout(res, delay));
            }
        }
    };

    async sendData(): Promise<void> {
        if (!this.parentStore.userData.wtLeadFormTime) {
            this.parentStore.userData.updateField('wtLeadFormTime', new Date().getTime());
        }

        this.resetQuotes();

        const recommendedProductsRequest = mapStateToEligibleProductsRequestData(this.parentStore.userData);

        const {phase1, utm} = this.parentStore.userData;
        /**
         * TODO just do this through rule engine
         * Quick fix conditional to ensure contacts with incomes over the $50k threshold entering
         * via paid sources are more likely to be recommended permanent products
         */
        this.parentStore.userData.recommendationOverridden = false;
        if (
            MARKETING_UTM_SOURCES.includes(utm?.source) &&
            phase1.annualHouseholdIncome &&
            phase1.annualHouseholdIncome >= 3
        ) {
            if (!phase1.features.includes('savings')) {
                recommendedProductsRequest.userData.investmentGoals.push('savings');
                this.parentStore.userData.recommendationOverridden = true;
            }
        }
        if (recommendedProductsRequest.userData.zip === '     ') recommendedProductsRequest.userData.zip = null;
        !prodMode && console.log('sending data:', JSON.stringify(recommendedProductsRequest));

        const {phase3} = this.parentStore.userData;

        // must null out products or the UI may fetch quotes for them from previous run
        phase3.updTemp('product', null);
        phase3.updRetir('product', null);
        phase3.updBest('iulProduct', null);
        phase3.updBest('termProduct', null);

        try {
            const eligibleProductsResponse: RecommendedProductsResponse = await this.retry(
                Phase3Api.getAllEligibleProducts,
                [recommendedProductsRequest],
                2,
                2000,
            );

            this.parentStore.logStore.logAction({
                action: 'recommended-product-send',
                data: recommendedProductsRequest,
            });

            if (eligibleProductsResponse) {
                const recommendedProductType = eligibleProductsResponse.productTypeRecommendation;

                const productTypes: ExtendedProductTypeCode[] = [
                    recommendedProductType,
                    // Add list of all product types as default in case recommendation is not available
                    ...[
                        ExtendedProductTypeCode.IUL,
                        ExtendedProductTypeCode.VUL,
                        ExtendedProductTypeCode.Term,
                        ExtendedProductTypeCode.Combo,
                    ],
                ];
                // check that recommended were actually recommended
                const filteredProductTypes = productTypes.filter((productType) => {
                    switch (productType) {
                        case ExtendedProductTypeCode.VUL:
                            return !!eligibleProductsResponse.vul && this.meetsVulLowerContributionLimit;
                        case ExtendedProductTypeCode.Combo:
                            return !!eligibleProductsResponse.combo;
                        case ExtendedProductTypeCode.Term:
                            return !!eligibleProductsResponse.term;
                        case ExtendedProductTypeCode.IUL:
                            return !!eligibleProductsResponse.iul;
                    }
                });

                this.updateField('recommendedProductTypeEnum', filteredProductTypes?.[0]);
                this.updateField('activeProduct', recommendedProductType?.toLowerCase() || null);
                this.parentStore.userData.phase4.updateField(
                    'leadCreationTimestamp',
                    eligibleProductsResponse.leadCreationTimestamp,
                );

                this.initTerm(eligibleProductsResponse.term);
                this.initBasic(eligibleProductsResponse.basic);
                this.initCombo(eligibleProductsResponse.combo);
                this.initIul(eligibleProductsResponse.iul);
                this.initVUL(eligibleProductsResponse.vul);
            }
        } catch (error) {
            console.error('Failed to fetch eligible products:', error);
        }
    }
}

export default CalculatorStore;
